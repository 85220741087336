import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    private readonly domain: string = '.' + environment.ROOT_DOMAIN;

    constructor() {}

    // Método para obter um cookie
    get(name: string): string | null {
        const cookies = document.cookie.split('; ');
        const cookie = cookies.find(row => row.startsWith(name + '='));
        return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
    }

    // Método para definir um cookie (path fixo e domain padrão)
    set(name: string, value: string, days: number, path: string = '/'): void {
        const expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        const cookieStr = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=${path}; domain=${this.domain};`;

        document.cookie = cookieStr;
    }

    // Método para excluir um cookie (path fixo e domain padrão)
    delete(name: string, path: string = '/'): void {
        const cookieStr = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${this.domain};`;

        document.cookie = cookieStr;
    }
}
